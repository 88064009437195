/* .background{
    width: 100% !important;
    background-color: white;
    height: 230px;
} */

.main #view-talent{
    background: white;
}

.photo{
    width: 200px;
    height: 200px;
    border-style: solid;
    border-color: white;
    border-width: 1px;
    border-radius: 50%;
}

.name-talent{
    color: #eec000;
    font-size: 25px;
    font-weight: 900px;
    text-transform: capitalize;
}

.camera{
    font-size: 24px;
}

a {
    background-color: transparent;
}

.list-tags-primary{
    color: #efb810 !important;
    border: 1px solid #f9df4f !important;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 13px;
    padding-right: 5px;
    border-radius: 5px;
    text-transform: capitalize;
}

.list-tags-primary i{
    border-right: 1px solid #efb810;
    color: orange;
    padding: 8px;
    font-size: 11px;
}

.list-tags-secundary{
    color: #c8ced3 !important;
    border: 1px solid #c8ced3 !important;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 13px;
    padding-right: 5px;
    border-radius: 5px;
    text-transform: capitalize;
}

.list-tags-secundary i{
    border-right: 1px solid #c8ced3;
    color: #c8ced3;
    padding: 8px;
    font-size: 11px;
}

.arrows-talent{
    max-width: 6.33333% !important;
}

.container-camera{
    font-size: 18px; 
    background-color: #eec000;
    color: white;
}

/*Columns After and Before*/
.before, .after{
    border-radius: 50%;
    color: rgb(50, 55, 61);
    background-color: white;
    border: none;
}

.before{
    margin-left: 115px;
    /*position: fixed;*/
}

.after{
    margin-left: 4px;
}

.container-before{
    height: 170px;
    top: 17rem;
    position: fixed;
    border-radius: 50%;
    width: 170px;
    margin-left: -130px;
    position: fixed;
    z-index: 1;
}

/* .container-images{
    width: 100%;
} */

.container-after{
    height: 170px;
    top: 17rem;
    position: fixed;
    border-radius: 50%;
    width: 170px;
    margin-left: 6px;
    position: fixed;
    z-index: 1;
    right: -103px;
}

.container-after:hover, .container-before:hover{
    opacity: 1;
    -webkit-transition: .25s ease-in-out .15s;
    transition: .25s ease-in-out .15s;
    background-color: rgb(50, 55, 61);
}

.after:hover, .before:hover{
    background-color: white;
}

/*Media Quyres------------------------*/

@media(max-width: 575px){
    .container-after, .container-before{
        top: 15rem
    }

    .photo{
        width: 140px;
        height: 140px;
    }

    .name-talent{
        font-size: 20px;
    }
}

@media(min-width: 576px){
    .container-after, .container-before{
        top: 17rem
    }

    .photo{
        width: 140px;
        height: 140px;
    }

    .name-talent{
        font-size: 20px;
        font-weight: 900px;
    }

    .data-secundary{
        margin-left: 0px;
    }
}

@media(min-width: 992px){
    .photo{
        width: 160px;
        height: 160px;
    }

    .data-secundary{
        margin-left: 25px;
    }
}
