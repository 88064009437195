.registerFast{
    margin: auto;
}

@media (min-width: 768px) {
    .container {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  
  /* Estilos para móvil */
  @media (max-width: 767px) {
    .imgcontainer{
        display: none;
    }
    .container {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
  }

.register-image {
  background-image: url('https://img.freepik.com/foto-gratis/joven-mujer-compras-traves-aplicacion-electronica_1163-3210.jpg?w=360&t=st=1686873053~exp=1686873653~hmac=cb0baf3d4cae92732f03dabbd01981bf730720a5fb433e65045456124b408c7c');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: brightness(90%);
  transform: scaleX(-1);
  position: relative;
  z-index: 0;
}

.register-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Capa negra con 85% de transparencia */
  z-index: 1;
}

#custom-card {
    height: 100%;
    border-radius: 0px !important;
    margin-bottom: 0px;
}


.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
}

.app-footer {
  margin-top: 0;
  flex-shrink: 0;
  /* Evita que el footer se expanda */
  margin-left: 0px !important;
}