.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Mínimo del 100% de la altura de la ventana visible */
}

.app-body-show {
  display: flex;
  flex: 1;
  /* Hace que ocupe el espacio restante */
  align-items: center;
  flex-direction: column !important;
  padding: 0rem 15rem;
}

@media (max-width: 1150px) {
  .app-body-show {
    padding: 0;
  }
}

.margin-app {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/* evento */
.info-porfolio {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 20px;
  background-color: #e4e5e6;
  /* gap: 1rem; */
}

.item-card {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
}

.subitem-card {
  /* margin-right: 10px !important; */
  border-radius: 10px 0px 0px 10px;
}

.subitem-left {
  border-radius: 0px 0px 0px 0px;
}

.item-last {
  border-radius: 0px 10px 10px 0px;
}

@media (max-width: 767px) {
  .subitem-card {
    margin-bottom: 10px !important;
    border-radius: 10px 10px 10px 10px;
  }

  .subitem-left {
    border-radius: 10px 10px 10px 10px;
  }

  .item-last {
    border-radius: 10px 10px 10px 10px;
    margin-top: 10px !important;
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  border-radius: 50%;
  /* width: 7rem; */
  height: 7rem;
  margin-bottom: 5px;
  max-width: 8rem;
}

.logo-finalClient {
  border-radius: 50%;
  /* width: 7rem; */
  height: 7rem;
  margin-bottom: 5px;
  max-width: 8rem;
}

.event-details {
  flex-grow: 1;
  padding-left: 20px;
}


/* talentos */
/*Imagen Seleccionado*/
.image-selected {
  width: 120px;
  height: 120px;
  border-style: solid;
  /* border-color: lightgrey; */
  border-width: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.containerRemove {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto;
  z-index: 2;
  position: absolute;
  left: 43px;
  background-color: #e4605e;
  top: 1px;
  opacity: 0.5;
  cursor: pointer;
}

.iconRemove {
  font-size: 86px;
  color: white;
  opacity: 0.5;
  margin-top: 6px;
}

.talent {
  transition: box-shadow 0.3s, transform 0.3s;
  /* display: table; */
}

.talent:hover {
  transform: scale(1.05);
}

.div-borde {
  border-bottom: 1px solid #cdcdcd;
  /* Un borde sólido de 1 píxel de ancho en color gris */
  padding: 5px 0px;
  margin: -5px 0px 15px 0px;
  width: auto !important;
}

.message-borde {
  padding-bottom: 1.7rem;
  margin-bottom: 1rem;
}

#whatsappIconStyle {
  position: absolute !important;
  bottom: 3rem !important;
  right: 3rem !important;
  cursor: pointer !important;
  font-size: 1.5rem;
  border-radius: 2rem;
}

@media (max-width: 1200px) {
  #whatsappIconStyle {
    right: 0rem !important;
  }
}

@media (max-width: 768px) {
  #whatsappIconStyle {
    right: 16rem !important;
  }
}

@media (max-width: 575px) {
  #whatsappIconStyle {
    right: 10rem !important;
  }
}

@media (max-width: 475px) {
  #whatsappIconStyle {
    right: 7rem !important;
  }
}

#icon-whatsapp {
  color: white;
}