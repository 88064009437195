// Variable overrides
/*body{
    font-size: 0.875rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}*/
@media(min-width: 320px){
    .container{
        margin-bottom: 20px;
    }
}

@media(min-width: 576px){
    .container{
        max-width: 710px;
        margin-bottom: 20px;
    }
}

@media(min-width: 768px){
    .container{
        max-width: 920px;
        margin-bottom: 20px;
    }
}

@media(min-width: 1200px){
    .container{
        max-width: 1200px;
        margin-bottom: 20px;
    }
}
