/* html, body, #root, .app {
    height: 100%;
    margin: 0;
} */

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-footer {
    margin-top: auto;
}

.app-body-show {
    display: flex;
    flex: 1;
    /* Hace que ocupe el espacio restante */
    align-items: center;
    flex-direction: column !important;
    padding: 0rem 15rem;
}

@media (max-width: 1150px) {
    .app-body-show {
        padding: 0;
    }
}

.margin-ajust {
    display: flex;
    justify-content: center;
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.event-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #e4e5e6;
    gap: 1rem;
}

.i-cards{
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 10px;
}

.i-padding {
    
}

/* Media query para dispositivos móviles */
@media (max-width: 767px) {
    .i-padding {
        margin-top: 3rem;
    }
}
