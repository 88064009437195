.page-register-producer-div-country{
    display: inline-flex;
}

.page-register-producer-logo-country{
    text-align: center;
    border-top: 1px solid #e4e7ea;
    border-bottom: 1px solid #e4e7ea;
    border-left: 1px solid #e4e7ea;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 53px;
    background-color: #f0f3f5
}

.page-register-producer-input-country{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: none !important;
}

.flag-country-page-register-producer{
    margin-bottom: 0px !important;
    width: 27px !important;
    margin-top: 3px;
    /*font-size: 19px*/
}


@media(min-width: 320px) and (max-width: 339px){
    .page-register-producer-input-country{
        width: 231px;
    }
}

@media(min-width: 340px) and (max-width: 369px){
    .page-register-producer-input-country{
        width: 255px;
    }
}

@media(min-width: 370px) and (max-width: 399px){
    .page-register-producer-input-country{
        width: 285px;
    }
}

@media(min-width: 400px) and (max-width: 429px){
    .page-register-producer-input-country{
        width: 315px;
    }
}

@media(min-width: 430px) and (max-width: 459px){
    .page-register-producer-input-country{
        width: 341px;
    }
}

@media(min-width: 460px) and (max-width: 489px){
    .page-register-producer-input-country{
        width: 371px;
    }
}

@media(min-width: 490px) and (max-width: 519px){
    .page-register-producer-input-country{
        width: 401px;
    }
}

@media(min-width: 520px) and (max-width: 549px){
    .page-register-producer-input-country{
        width: 428px;
    }
}

@media(min-width: 550px) and (max-width: 576px) {
    .page-register-producer-input-country{
        width: 457px;
    }
}

@media(min-width: 1200px){
    .page-register-producer-input-country{
        width: 192px;
    }
}