input:-webkit-autofill {
    background-color: white !important
}

.login {
    /* max-width: 800px; */
    margin: auto;
    display: flex;
    height: 100%;
}

.register {
    text-decoration: none;
    color: #20a8d8
}

.eye {
    font-size: 23px;
    align-self: center;
    /* padding-left: 11px; */
    background-color: #e8f0fe;
    border: 1px solid #f0f3f5;
    border-radius: 0px 5px 5px 0px;
}

@media (min-width: 768px) {
    .container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

/* Estilos para móvil */
@media (max-width: 767px) {
    .imgcontainer {
        display: none;
    }

    .container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.image-container {
    background-image: url('https://niriun.com/wp-content/uploads/2023/08/Portada-Niriun.webp');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: brightness(90%);
    transform: scaleX(-1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.login-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.text-overlay {
    position: absolute;
    bottom: 140px;
    right: 30px;
    color: white;
    font-size: 1.5rem;
    text-align: justify;
    z-index: 2;
    padding: 10px;
    max-width: 55%;
}

.no-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

#custom-card {
    height: 100%;
    border-radius: 0px !important;
    margin-bottom: 0px;
}

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #ffffff;
}

.app-footer {
    margin-top: 0;
    flex-shrink: 0;
    /* Evita que el footer se expanda */
    margin-left: 0px !important;
}

.body-container {
    height: 100%;
    flex: 1;
    /* Permite que el contenedor principal ocupe todo el espacio disponible */
}

.btn-login {
    width: 100%;
}

.center {
    text-align: center;
}

.top {
    margin-top: 4rem;
}