.delete-data {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0.25rem;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-zone {
    margin: 0px !important;
    padding: 1px !important;
    width: 3rem !important;
}

.btn-cabify {
    width: 100%;
    padding: 0.7rem !important;
}

.app {
    min-height: 97vh !important;
}


.cabify-container {
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
}

@media (max-width: 991px) {
    .cabify-container {
        margin-top: 3rem;
    }
}

.container-cabify-row{
    align-items: flex-start;
}

.card-overlay{
    position: relative;
    z-index: 2;
    background-color: white;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 8px;
}

/* En pantallas pequeñas (móviles), el formulario estará en la parte inferior */
@media (max-width: 767px) {
    .container-cabify-row{
        align-items: flex-end;
    }
}

.full-screen-map{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* bot[on de cobro */
.price-box {
    width: 100%;
    background-color: #f8f9fa; /* Fondo claro */
    border-radius: 0.25rem; /* Bordes redondeados */
    padding: 10px 20px; /* Espaciado interno */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px; /* Espacio entre el ícono y el texto */
}

.price-text {
    margin-bottom: 0px;
    font-size: 1.5rem; /* Tamaño de la fuente más grande */
    font-weight: bold; /* Negrita */
    color: #28a745; /* Color verde o el que prefieras */
}

.fas.fa-money-bill-wave {
    font-size: 1.5rem; /* Tamaño del ícono */
    color: #28a745; /* Color del ícono a juego */
}

.label-vehicle{
    font-size: 14px !important;
    margin-bottom: 0rem !important;
}

.origin-destiny{
    position: relative;
}

.btn-row {
    top: 53%;
    left: 92%;
    padding: 1px 7px !important;
    position: absolute;
    background-color: transparent !important; 
    border: 1px solid transparent !important;
}

.custom-icon-cabify{
    font-size: large !important;
}