.ReactModal__Overlay  {
    z-index: 99999999 !important;
}

.ril-toolbar{
    background-color: transparent;
}

.ril-caption{
    background-color: transparent;
    max-width: 426.86px;
    margin: auto;
}

.ril-caption-content{
    margin: auto;
    color: black;
    font-size: 14px;
}

@media(max-width: 576px){
    .ril__image{
        margin-left: 0px;
    }

    .ril-caption-content{
        color: white;
    }
}
