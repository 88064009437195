/*.tabs{
    background-color: white
}*/

.nav-tabs .nav-link.active.tabs{ 
    background: #eec000 !important;
}

.tabs-info-talent{
    box-shadow: 0px 0px 8px -2px grey;
}