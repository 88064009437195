.page-main {
  /* background: white; */
  width: 100%;
  height: 80vh;
}

.page-main-container {
  padding-top: 40px;
}

@media(min-width: 320px) {
  .photo-model {
    width: 206px;
    height: 378px;
  }

  .col-revisa-email {
    margin-top: 1rem !important;
  }

  .logo-empresa {
    width: 253px;
    height: 74px;
    text-align: center;
  }

  .parrafo-revisa-email {
    font-size: 1.30rem !important;
  }

  .row-parrafo-extra {
    margin-top: 2rem !important;
  }

  .parrafo-extra {
    font-size: 1.1rem !important;
  }

  .col-photo-model {
    margin-top: 20px;
  }
}

@media(min-width: 768px) {
  .parrafo-revisa-email {
    font-size: 1.75rem !important;
  }

  .row-parrafo-extra {
    margin-top: 3rem !important;
  }

  .parrafo-extra {
    font-size: 1.5rem !important;
  }

  .col-photo-model {
    margin-top: 0%
  }

  .photo-model {
    width: 279px;
    height: 508px;
  }
}

@media (min-width: 992px) {
  .photo-model {
    width: 316px;
    height: 508px;
  }
}

@media (min-width: 1200px) {
  .photo-model {
    width: 316px;
    height: 535px;
  }

  .logo-empresa {
    width: 320px;
    height: 120px;
  }
}
