/* .header-request-agent{
    text-align: center;
    margin: auto;
} */

.photo-user{
    width: 34px;
}

/* @media(max-width: 576px){
    .header-request-agent-label{
        display: none;
    }
} */

@media(min-width: 993px){
    .button-apply-agent{
        margin: auto;
        border-radius: 50em !important; 
    }
}

@media(max-width: 992px){
    .button-apply-agent{
        position: fixed;
        bottom: 1em;
        right: 1em;
        border-radius: 50% !important;
        padding: 1em;
    }

    .label-button-apply-agent{
        display: none;
    }
}


