.gallery {
    display: block;
    cursor: pointer;
}

.item-gallery {
    display: inline-block;
    margin-right: -4px;
    vertical-align: top;
    width: 25%;
}

.pencil-icon {
    font-size: 24px;
    cursor: pointer;
}

.pencil-icon:hover {
    color: grey;
    /* Cambia el color a gris cuando el mouse pasa por encima */
}

.img-talent{
    object-fit: cover;
    width: 100%;
    object-position: center;
    height: 20rem;
    margin-top: 10px;
}

@media (max-width: 768px) { /* Ajusta el tamaño según el breakpoint que definas para móviles */
    .img-talent {
      height: 20vh;
    }
  }
  
  @media (max-width: 1350px) { /* Ajusta el tamaño según el breakpoint que definas para móviles */
    .img-talent {
      height: 25vh;
    }
  }

  @media (max-width: 1080px) { /* Ajusta el tamaño según el breakpoint que definas para móviles */
    .img-talent {
      height: 18vh;
    }
  }