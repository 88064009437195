.photoInfo{
    width: 180px;
    height: 180px;
}

.title-talent{
    font-size: 20px;
    color: #eec000;
}

.physical-characteristics label span{
    color: #eec000;
}